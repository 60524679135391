// deps
import React from "react"
import { graphql } from "gatsby"
import { useMediaQuery } from "react-responsive"

// components
import Layout from "../layouts/layout"
import Img from "gatsby-image"
import SEO from "../components/seo"

export default function Werkwijze({ location, data }) {

  const isMobile = useMediaQuery({ maxWidth: 1200 })

  return (
    <Layout loc={location}>
      <SEO
        title="werkwijze"
        description="Hier vind u onze aanpak omtrent Business Resilience en continuiteit aan de hand van een BCP"
      ></SEO>
      <div className="subHeader">
        <h1>
          Waarom, hoe en wat van onze diensten<span className="dot">.</span>
        </h1>
        <p>
          Ons credo is: "Kennis moet je delen, niet bezitten", om zodoende
          organisaties te kunnen helpen, ondersteunen bij het voorbereid zijn op
          ongewenste en soms onverwachte gebeurtenissen die de strategie van een
          organisatie in gevaar kunnen brengen.
        </p>
      </div>
      <div className="textContainer">
        <h2>
          Onze werkwijze<span className="dot">.</span>
        </h2>
        <p>
          De afhankelijkheid van bedrijven wordt steeds groter. De één kan niet
          zonder de ander. En wat nu als die ander wegvalt. Onze missie is er
          voor te zorgen dat uw organisatie altijd door blijft gaan. Ook als de
          omstandigheden (soms) tegenzitten. Dit doen we door het leveren van
          maatwerk, plannen en maatregelen die samen met u worden ontworpen en
          ingevoerd.
        </p>
        <h2>
          Waarom?<span className="dot">.</span>
        </h2>
        <ul>
          <li>
            <p>
              We zien dat de toekomst onzekerder wordt. De omstandigheden voor
              bedrijven veranderen voortdurend en risico’s en bedreigingen nemen
              toe.
            </p>
          </li>
          <li>
            <p>
              Veel bedrijven zijn zich hiervan bewust alleen weten ze vaak niet
              hoe hierop adequaat te reageren.
            </p>
          </li>
          <li>
            <p>
              Veerkrachtig zijn en op veranderingen kunnen reageren om een
              calamiteit of crisis te voorkomen.
            </p>
          </li>
        </ul>
        <br />
        <h2>
          Hoe?<span className="dot">.</span>
        </h2>
        <ul>
          <li>
            <p>
              Vooraf al nadenken over het veerkrachtig maken van uw organisatie.
              Wat is er al geregeld en wat moet er nog geregeld worden.
            </p>
          </li>
          <li>
            <p>
              Continuïteit van uw organisatie, op korte en lange termijn,
              waarborgen door het inrichten van het Business Continuity
              Management Systeem (BCMS).
            </p>
          </li>
        </ul>
        <br />
        <div
          style={{
            display: "grid",
            gridTemplateColumns: isMobile ? "100%" : "50% 50%",
          }}
        >
          <div style={{ gridColumn: "1", gridRow: "1" }}>
            <h2>
              Wat?<span className="dot">.</span>
            </h2>
            <ul>
              <li>
                <p>
                  Het realiseren en testen, oefenen van een Business Continuity
                  Plan (BCP) waarmee ongewenste omstandigheden het hoofd kan
                  worden geboden.
                </p>
              </li>
              <li>
                <p>
                  BRCG beantwoord al uw vragen op het gebied van continuïteit en
                  kan u voorzien van de nodige adviezen.
                </p>
              </li>
              <li>
                <p>
                  BRCG kan voor kennisoverdracht van BCM aan uw medewerkers
                  zorgdragen, &#39;in house&#39; of op een te bepalen locatie.
                </p>
              </li>
              <li>
                <p>Het BCMS bestaat uit:</p>
                <ul>
                  <li>
                    <p>Bedrijfshulpverleningsplannen</p>
                  </li>
                  <li>
                    <p>Crisis- Communicatie Plannen</p>
                  </li>
                  <li>
                    <p>
                      Herstelplannen, IT uitwijk, Werkplek uitwijk, Cyber
                      Incident response plan
                    </p>
                  </li>
                </ul>
              </li>
              <li>
                <p>​Dit plan wordt op maat gemaakt voor uw organisatie.</p>
              </li>
            </ul>
          </div>
          <div
            style={{
              display: "grid",
              padding: "3em",
              alignItems: "center",
              // justifyItems: "center",
              gridRow: isMobile ? "2" : "1",
            }}
          >
            <Img
              fluid={data.file.childImageSharp.fluid}
              alt="Business Continuety Plan"
              style={{ marginBottom: "2em" }}
            ></Img>
          </div>
        </div>
        <br />
        <p>
          Kortom, veerkrachtig worden. En dat kan alleen als je samenwerkt.
          Immers, wie alleen reist, reist sneller, wie samen reist komt verder.
        </p>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query {
    file(relativePath: { eq: "algemeen/bcp.png" }) {
      childImageSharp {
        fluid(maxWidth: 1000, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
  }
`
